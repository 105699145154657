import https from '@/router/https'
//获取订单id详情
export const getOrderIdDetail = (params) => {
    return https.fetchGet('/mallapp/orderinfo/YDOrderDetails', params);
}
//取消订单
export const sendCancelOrder = (params) => {
    return https.fetchPost(`/mallapp/orderinfo/updateMergeOrderInfo?mergeId=${params.mergeId}&reason=${params.reason}`)
}
//点击确认收货
export const confirmGetGoods = (params) => {
    return https.fetchGet('/mallapp/orderinfo/YDConfirmGoods', params)
}
//点击确认收货，售后专用
export const confirmGetGoodsYD = (params) => {
    return https.fetchGet('/mallapp/orderinfo/YDConfirmGoods', params)
}
//申请退款
export const ydApplyForRefund = (data) => {
    return https.fetchPost('/mallapp/orderrefunds/YDApplyForRefund', data)
}
//获取物流信息
export const getOrderLogistics = () => {
    return https.fetchGet('/mallapp/orderrefunds/dict?type=LOGISTICS');
}
//获取支付接口信息
export const getPayStatus = () => {
    return https.fetchGet('/mallapp/orderinfo/cloudAndWelfare');
}
//获取客服电话
export const getTelephone = () => {
    return https.fetchGet('/mallapp/appversion/serverPhone?type=cloudServerPhone');
}