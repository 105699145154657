import https from '@/router/https'
//获取换货详情
export const getChangeDetail = (id) => {
    return https.fetchGet(`/mallapp/orderrefunds/YDAffirmOrderDetails?id=${id}`);
}
//撤销申请
export const cancelChange = (data) => {
    return https.fetchPost('/mallapp/orderrefunds/YDOrderCancel', data);
}



